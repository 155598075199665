import { IoMdMenu } from "react-icons/io";
import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../images/RBLogo.png";

const Navbar = ({ navbarDark }) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const newPageLinks = [
    {
      anchorName: "Home",
      link: "/home",
    },
    {
      anchorName: "Services",
      link: "/services",
    },
    {
      anchorName: "About",
      link: "/about",
    },
    {
      anchorName: "Process",
      link: "/process",
    },
    {
      anchorName: "Features",
      link: "/features",
    },
    // {
    //   anchorName: "Blogs",
    //   link: "/blogs",
    // },
    {
      anchorName: "Contact",
      link: "/contact",
    },
    {
      anchorName: "Resume Maker",
      link: "/build-resume",
    },
    {
      anchorName: "ATS Checker",
      link: "/ats-checker",
    },
  ];

  return (
    <Fragment>
      <div
        className={`min-h-[3rem] fixed w-[100vw] top-0 left-0 right-0 z-[100] whitebg overflow-x-hidden  ${
          navbarDark ? "shadow-md" : ""
        }`}
      >
        <div className="flex items-center justify-between px-5 lg:justify-between">
          <img
            src={Logo}
            alt="Logo"
            className="w-[10vmax] h-12 object-contain"
          />
          <div
            className="block lg:hidden"
            onClick={() => {
              setNavbarOpen(!navbarOpen);
            }}
          >
            <IoMdMenu className="w-6 h-6" />
          </div>
          <div
            className={`hidden lg:flex lg:items-center gap-10 font-semibold ${
              navbarDark ? "" : "border-b-1 border-white-50"
            } py-3`}
          ></div>
          <div
            className={`hidden lg:flex lg:items-center gap-8 font-semibold ${
              navbarDark ? "" : "border-b-1 border-white-50"
            } py-3`}
          >
            {newPageLinks.map(({ link, anchorName }, index) => (
              <NavLink
                className={"bg-black-600 p-2 rounded-lg"}
                to={link}
                key={index}
              >
                {anchorName}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`fixed top-0 ${
          navbarOpen ? "left-0" : "left-full"
        } w-full h-screen flex flex-col gap-6 p-5 items-center justify-center z-50 bg-[#3b82f6] bg-opacity-20 text-white font-semibold text-lg backdrop-blur-lg transition-all`}
        onClick={() => setNavbarOpen(false)}
      >
        {newPageLinks.map(({ link, anchorName }, index) => {
          const classnames = `no-underline border-1 w-full text-center p-2 rounded-md border-slate-700 border-opacity-60`;
          return (
            <NavLink
              key={index}
              className={({ isActive }) =>
                isActive
                  ? `${classnames} text-[#3b82f6] bg-white`
                  : classnames + " bg-[#3b82f6] text-white"
              }
              to={link}
            >
              {anchorName}
            </NavLink>
          );
        })}
      </div>
    </Fragment>
  );
};

export default Navbar;
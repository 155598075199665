import React, { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./AtsChecker.css";
import mammoth from "mammoth";
import "@react-pdf-viewer/core/lib/styles/index.css";

const AtsChecker = () => {
  const [file, setFile] = useState(null);
  const [role, setRole] = useState("");
  const [result, setResult] = useState(null);
  const [previewContent, setPreviewContent] = useState(null);
  const [fileType, setFileType] = useState("");
  const navigate = useNavigate();
  const resultRef = useRef(null);

  const handleFileChange = async (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
    const mimeType = uploadedFile.type;

    if (mimeType === "application/pdf") {
      setFileType("pdf");
      setPreviewContent(URL.createObjectURL(uploadedFile));
    } else if (
      mimeType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setFileType("docx");
      const arrayBuffer = await uploadedFile.arrayBuffer();
      const docxContent = await mammoth.extractRawText({ arrayBuffer });
      setPreviewContent(docxContent.value);
    } else {
      setFileType("unsupported");
      setPreviewContent(null);
      alert("Unsupported file type. Only PDF and DOCX are allowed.");
    }
  };

  const handleRoleChange = useCallback((e) => setRole(e.target.value), []);

  const uploadFile = async () => {
    if (!file || !role) {
      alert("Please fill all fields and select a file");
      return;
    }

    const formData = new FormData();
    formData.append("resume", file);
    formData.append("role", role);

    try {
      const res = await fetch("https://resumebyte.com/api/ats-checker", {
        method: "POST",
        body: formData,
      });

      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(errorText);
      }

      const data = await res.json();
      setResult(data);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  const animateProgress = (type, value) => {
    const circle = document.querySelector(`.progress-circle-${type}`);
    if (!circle) return; // Ensure the circle exists

    const radius = circle.r.baseVal.value;
    const circumference = 2 * Math.PI * radius;

    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = `${circumference}`;

    const offset = circumference - (value / 100) * circumference;
    setTimeout(() => {
      circle.style.strokeDashoffset = offset;
    }, 500);
  };

  useEffect(() => {
    if (result && resultRef.current) {
      resultRef.current.scrollIntoView({ behavior: "smooth" });

      // Animate each score individually
      if (result.overallScore)
        animateProgress("overallScore", result.overallScore);
      if (result.grammarScore)
        animateProgress("grammarScore", result.grammarScore);
      if (result.spellingScore)
        animateProgress("spellingScore", result.spellingScore);
      if (result.atsFriendlyScore)
        animateProgress("atsFriendlyScore", result.atsFriendlyScore);
      if (result.sectionImpactScore)
        animateProgress("sectionImpactScore", result.sectionImpactScore);
    }
  }, [result]);

  const getCircleColor = (percentage) => {
    if (percentage >= 90) return "green"; // Excellent score
    if (percentage >= 70) return "orange"; // Average score
    return "red"; // Low score
  };

  const obfuscateText = (text) => {
    return text
      .split("")
      .map((char, index) => (index % 2 === 0 ? char : "*"))
      .join("");
  };

  return (
    <>
      <div className="ats-cont">
        <div className="ats-left">
          <h2>
            <span>ResumeByte:</span> <br /> Your Gateway to Job Success
          </h2>
          <p>
            Boost your chances of landing interviews with our AI-powered ATS
            Resume Checker
          </p>
          <div className="input-box">
            <div className="input-box1">
              <p>
                Enter the position you're applying for, and our ATS checker will
                tailor the analysis with personalized insights on keywords,
                skills, and optimization.
              </p>
              <div className="coolinput">
                <label htmlFor="input" className="text">
                  Position Applying For:
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  value={role}
                  onChange={handleRoleChange}
                  name="input"
                  className="input"
                />
              </div>
            </div>
            <div className="input-box2">
              <label htmlFor="file" className="file-upload-label">
                <div className="file-upload-design">
                  <svg viewBox="0 0 640 512" height="1em">
                    <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                  </svg>
                  <p>Drag and Drop</p>
                  <p>or</p>
                  <span className="browse-button">Browse file</span>
                </div>
                <input id="file" type="file" onChange={handleFileChange} />
              </label>
              <button onClick={uploadFile} className="ats-btn">
                Submit
              </button>
            </div>
          </div>
        </div>
        <div className="ats-right">
          <img
            src="https://enhancv.com/_next/static/images/ats-checker-716fd8ba725f0a2f6ed622ea30fb0538.svg"
            alt="graphic"
            loading="lazy"
          />
        </div>
      </div>
      {result && (
        <div className="ats-result" ref={resultRef}>
          <div className="result-left"></div>
          <div className="result-right"></div>
          <div className="result-float">
            <div className="float-left">
              <h3>Analysis Report</h3>

              {/* Overall Score */}
              <div className="score-container">
                <strong>Overall Score :</strong>
                <svg className="progress-ring" width="80" height="80">
                  <circle
                    className="progress-circle-overallScore"
                    cx="40"
                    cy="40"
                    r="30"
                    stroke={getCircleColor(result.overallScore || 0)}
                    strokeWidth="6"
                    fill="transparent"
                    style={{
                      strokeDasharray: "188", // Adjusted circumference for smaller circle
                      strokeDashoffset: `${
                        188 - (result.overallScore / 100) * 188 || 188
                      }`,
                      transition: "stroke-dashoffset 0.5s ease",
                    }}
                  />
                  <text
                    x="40"
                    y="45"
                    textAnchor="middle"
                    fontSize="14"
                    fill={getCircleColor(result.overallScore || 0)}
                    fontWeight="bold"
                  >
                    {typeof result.overallScore === "number"
                      ? `${result.overallScore}%`
                      : "N/A"}
                  </text>
                </svg>
              </div>

              {/* Grammar Score */}
              <div className="score-container">
                <strong>Grammar Score :</strong>
                <svg className="progress-ring" width="80" height="80">
                  <circle
                    className="progress-circle-grammarScore"
                    cx="40"
                    cy="40"
                    r="30"
                    stroke={getCircleColor(result.grammarScore || 0)}
                    strokeWidth="6"
                    fill="transparent"
                    style={{
                      strokeDasharray: "188",
                      strokeDashoffset:
                        typeof result.grammarScore === "number"
                          ? 188 - (result.grammarScore / 100) * 188
                          : 188, // Avoid fallback always being used
                      transition: "stroke-dashoffset 0.5s ease",
                    }}
                  />
                  <text
                    x="40"
                    y="45"
                    textAnchor="middle"
                    fontSize="14"
                    fill={getCircleColor(result.grammarScore || 0)}
                    fontWeight="bold"
                  >
                    {typeof result.grammarScore === "number"
                      ? `${result.grammarScore}%`
                      : "N/A"}
                  </text>
                </svg>
              </div>

              {/* Spelling Score */}
              <div className="score-container">
                <strong>Spelling Score :</strong>
                <svg className="progress-ring" width="80" height="80">
                  <circle
                    className="progress-circle-spellingScore"
                    cx="40"
                    cy="40"
                    r="30"
                    stroke={getCircleColor(result.spellingScore || 0)}
                    strokeWidth="6"
                    fill="transparent"
                    style={{
                      strokeDasharray: "188",
                      strokeDashoffset:
                        typeof result.spellingScore === "number"
                          ? 188 - (result.spellingScore / 100) * 188
                          : 188, // Avoid fallback always being used
                      transition: "stroke-dashoffset 0.5s ease",
                    }}
                  />
                  <text
                    x="40"
                    y="45"
                    textAnchor="middle"
                    fontSize="14"
                    fill={getCircleColor(result.spellingScore || 0)}
                    fontWeight="bold"
                  >
                    {typeof result.spellingScore === "number"
                      ? `${result.spellingScore}%`
                      : "N/A"}
                  </text>
                </svg>
              </div>

              {/* ATS Friendliness */}
              <div className="score-container">
                <strong>ATS Friendliness :</strong>
                <svg className="progress-ring" width="80" height="80">
                  <circle
                    className="progress-circle-atsFriendlyScore"
                    cx="40"
                    cy="40"
                    r="30"
                    stroke={getCircleColor(result.atsFriendlyScore || 0)}
                    strokeWidth="6"
                    fill="transparent"
                    style={{
                      strokeDasharray: "188",
                      strokeDashoffset:
                        typeof result.atsFriendlyScore === "number"
                          ? 188 - (result.atsFriendlyScore / 100) * 188
                          : 188, // Avoid fallback always being used
                      transition: "stroke-dashoffset 0.5s ease",
                    }}
                  />
                  <text
                    x="40"
                    y="45"
                    textAnchor="middle"
                    fontSize="14"
                    fill={getCircleColor(result.atsFriendlyScore || 0)}
                    fontWeight="bold"
                  >
                    {typeof result.atsFriendlyScore === "number"
                      ? `${result.atsFriendlyScore}%`
                      : "N/A"}
                  </text>
                </svg>
              </div>

              {/* Section Impactfulness */}
              <div className="score-container">
                <strong>Section Impactfulness :</strong>
                <svg className="progress-ring" width="80" height="80">
                  <circle
                    className="progress-circle-sectionImpactScore"
                    cx="40"
                    cy="40"
                    r="30"
                    stroke={getCircleColor(result.sectionImpactScore || 0)}
                    strokeWidth="6"
                    fill="transparent"
                    style={{
                      strokeDasharray: "188",
                      strokeDashoffset:
                        typeof result.sectionImpactScore === "number"
                          ? 188 - (result.sectionImpactScore / 100) * 188
                          : 188, // Avoid fallback always being used
                      transition: "stroke-dashoffset 0.5s ease",
                    }}
                  />
                  <text
                    x="40"
                    y="45"
                    textAnchor="middle"
                    fontSize="14"
                    fill={getCircleColor(result.sectionImpactScore || 0)}
                    fontWeight="bold"
                  >
                    {typeof result.sectionImpactScore === "number"
                      ? `${result.sectionImpactScore}%`
                      : "N/A"}
                  </text>
                </svg>
              </div>
            </div>

            <div className="float-right">
              <ul>
                {result.improvementSuggestions &&
                  Object.entries(result.improvementSuggestions).map(
                    ([section, details]) => (
                      <li key={section} className="suggestion-item">
                        <strong>{section}:</strong>
                        <span> {details.suggestion}</span>
                        <br />
                        <em className="example">
                          Suggestion:{" "}
                          <span
                            className="blurred-text"
                            data-text={obfuscateText(details.example)}
                          >
                            {obfuscateText(details.example)}
                          </span>
                        </em>
                        <button
                          className="unlock-button"
                          onClick={() => navigate("/build-resume")}
                        >
                          Unlock Now
                        </button>
                      </li>
                    )
                  )}
              </ul>
              <button onClick={() => navigate("/build-resume")}>
                Build Enhanced Resume With Us!
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AtsChecker;
